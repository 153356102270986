<ng-container *ngIf="{ gradeLevels: gradeLevelsService.levels$ | async } as sub">
  <div class="card">
    <div class="content-type-icon">
      <app-content-type-icon
        [contentType]="data.contentType"
        [contentTypeIconSize]="ContentTypeIconSize.LARGE"
      ></app-content-type-icon>
    </div>
    <div class="card-content">
      <h4 class="heading-4 clamp cart-item-title" data-line-clamp="2">{{ data.title }}</h4>
      <ng-template #topicsTooltipContent>
        <ul>
          <li *ngFor="let topic of data.topics">{{ topic.name }}</li>
        </ul>
      </ng-template>
      <dl
        class="meta-text clamp"
        data-line-clamp="1"
        [appTooltip]="topicsTooltipContent"
        [appTooltipIfLineClamped]="true"
      >
        <ng-container *ngIf="data.gradeLevelIds.length">
          <dt class="meta-text-term">{{ 'app.labels.grade-level' | translate }}</dt>
          <dd class="meta-text-description">
            {{ data.gradeLevelIds | formatGradeLevelSpan }}
          </dd>
        </ng-container>

        <ng-container *ngIf="hasMetaCount">
          <dt class="meta-text-term">
            {{ 'content.content-type-count.' + (data.contentType | pascalToKebab) | translate: { count: metaCount } }}
          </dt>
          <dd class="meta-text-description">
            <span class="content-type-meta-value">{{ metaCount }}</span
            >&nbsp;<span class="content-type-meta-label">{{
              'content.content-type-count.' + (data.contentType | pascalToKebab) | translate: { count: metaCount }
            }}</span>
          </dd>
        </ng-container>
        <ng-container *ngIf="data.topics.length">
          <dt class="meta-text-term">{{ 'app.labels.topics' | translate }}</dt>
          <dd class="meta-text-description">
            {{ data.topics | readableArray: { objectKey: 'name' } }}
          </dd>
        </ng-container>
      </dl>

      <div *ngIf="displaySettingsArea" class="settings-area">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</ng-container>
